html,
body {
  height: 100%;
  font-family: sans-serif;
}

body {
  margin: 0;
  padding: 0;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.footer-outer {
  margin-top: auto;
}
.footer {
  width:100%;
  text-align: center;
  background-color: #fff8d4;
  color: rgba(0, 0, 0, 0.5);
  font-weight: 600;
  bottom:0;
  padding: 5px;
  box-shadow: 0 0px 15px 0px #70706e;
}
.footer p {
  margin: 0 auto;
}
.footer p a:link {
  text-decoration: none;
}