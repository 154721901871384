.MapsComponent h1 {
    font-family: 'Circular-Loom';
      color: #000;
      margin: 0 30px 10px;
}
.border-right-0 {
    border-right: 0!important;
}
  
.small-box {
width: 120px;
height: auto;
top: 0;
z-index: 999;
left: -10px;
border-radius: 10px;
border: 3px solid #000;
}
.small-box p {
margin: 2px 0 -2px 0;
}
.small-box-text {
font-size: 15px;
}
.small-box-internal {
width: 80%;
height: auto;
background: rgb(243, 239, 5);
margin: auto;
border: 2px solid #000;
line-height: 1;
padding: 3px;
margin-bottom: 4px;
}
.small-box-internal i {
font-weight: 900;
font-size: 35px;
text-align: center;
display: flow-root;
}
.rotate {
transform: rotate(-90deg);


/* Legacy vendor prefixes that you probably don't need... */

/* Safari */
-webkit-transform: rotate(-180deg);

/* Firefox */
-moz-transform: rotate(-180deg);

/* IE */
-ms-transform: rotate(-180deg);

/* Opera */
-o-transform: rotate(-180deg);
}

.first-box {
border: 2px solid;
border-radius: 30px;
width:100%;
height: auto;
margin: 0 auto;
}
.first-box .col-text-1 {
border-bottom: 1px solid;
    padding: 10px;
    margin: 0;
}
.first-box .rsi-box .col {
border-right: 1px solid;
}
.first-box .col-text {
padding: 20px;
margin: 0;
font-size: 12px;
}
.first-box .rsi-box:last-child.col {
border-right: none;
}
.qr-code {
padding: 15px;
}
.qr-border {
border: 2px solid #000;
padding: 0;
}
.location-input{
position: absolute;
z-index: 9;
left: 20%;
}
.locations-box {
border: 2px solid #000;
border-radius: 8px;
margin: 0 4px;
min-height: 250px;
}
#singleMap {
border-radius: 8px
}
.locations-box-first.col {
border-right: 2px solid #000;
border-bottom: 2px solid #000;
}
.locations-box-second.col {
border-bottom: 2px solid #000;
}
.locations-box-third.col {
border-right: 2px solid #000;
}
.space-125{
min-height: 125px;
padding: 10px;
}
.box-heading {
display: inline-block;
width: 100%;
font-size: 10px;
font-weight: 500;
}
.box-heading-internal {
display: inline-block;
width: 100%;
font-size: 10px;
line-height: 1;
}
.icon-ui {
margin: 0 auto;
display: inline-block;
}
.list-group button {
padding: 4px 40px;
}
.location-icon {
float: right;
}
.modal-title {
font-size: 16px!important;
}
.loc-manager {
border: 2px solid;
padding: 10px;
border-radius: 10px;
width: 80%;
height: 20%;
line-height: 1;
font-size: 12px;
}
.location-steps td, .location-steps span{
font-weight: 700;
}


/* Toggle Styles */

#wrapper {
    padding-left: 0;
    padding-top: 72px;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }
  
  #wrapper.toggled {
    padding-left: 250px;
  }
  
  #sidebar-wrapper {
    z-index: 1000;
    position: fixed;
    left: 250px;
    width: 0;
    height: 100%;
    margin-left: -250px;
    overflow-y: auto;
    background: #282c34e3;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }
  
  #wrapper.toggled #sidebar-wrapper {
    width: 250px;
  }
/*   
  #page-content-wrapper {
    width: 100%;
    position: absolute;
    padding: 15px;
  } */
  
  #wrapper.toggled #page-content-wrapper {
    position: absolute;
    margin-right: -250px;
  }
  
  /* Sidebar Styles */
  
  .sidebar-nav {
    position: absolute;
    top: 0;
    width: 250px;
    margin: 0;
    padding: 0;
    list-style: none;
  }
  
  .sidebar-nav li {
    text-indent: 20px;
    line-height: 40px;
  }
  
  .sidebar-nav li a {
    display: block;
    text-decoration: none;
    color: #999999;
  }
  
  .sidebar-nav li a:hover {
    text-decoration: none;
    color: #fff;
    background: rgba(255,255,255,0.2);
  }
  
  .sidebar-nav li a:active,
  .sidebar-nav li a:focus {
    text-decoration: none;
  }
  
  .nav-link.active {
    font-weight: 900;
  }
  .sidebar-nav > .sidebar-brand {
    height: 65px;
    font-size: 18px;
    line-height: 60px;
    width: 224px;
  }
  
  .sidebar-nav > .sidebar-brand a {
    color: #999999;
  }
  
  .sidebar-nav > .sidebar-brand a:hover {
    color: #fff;
    background: none;
  }
  
  .toggleButton {
    position: absolute;
    top: 75px;
    left: 226px;
    color: #fff;
    z-index: 9999;
    cursor: pointer;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }
  
  #wrapper .toggleButton.toggleClose {
    color: #000;
    cursor: pointer;
    left: 5px;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }
  
  @media(min-width:768px) {
    #wrapper {
        padding-left: 250px;
    }
  
    #wrapper.toggled {
        padding-left: 0;
    }
  
    #sidebar-wrapper {
        width: 250px;
    }
  
    #wrapper.toggled #sidebar-wrapper {
        width: 0;
    }
  
    #page-content-wrapper {
        padding: 20px;
        position: relative;
    }
  
    #wrapper.toggled #page-content-wrapper {
        position: relative;
        margin-right: 0;
    }
  }

  .form-outer {
    background-color: #fff;
    border-radius: 20px;
    border: 2px solid #d8d8d9a8;
    -webkit-box-shadow: 5px 5px 15px 5px #000000; 
    box-shadow: 5px 5px 15px 5px #70706e;
  }

  .page-item.active .visually-hidden {
    display: none;
  }
  .overlay {
    position: fixed;
    display: none;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0 0 0 / 31%);
    z-index: 2;
    cursor: pointer;
  }
  ._loading_overlay_wrapper._loading_overlay_wrapper--active.css-79elbk {
    position: unset;
}

.chopline {
    width: 200px;
    overflow: hidden;
    height: 3em;
    line-height: 1.4em;
}

@media (prefers-reduced-motion: no-preference) {
  .logo-2 {
    animation: App-logo-spin infinite 50s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.brand-name {
    font-size: 25px;
    font-weight: 600;
    color: #5a5959;
    letter-spacing: -1px;
}
.navbar-light .navbar-nav .nav-link {
  font-weight: 600;
}

.overlay1 {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 1;
  top: 0px;
  left: 0;
  background-color: rgb(0 0 0 / 0%);
  overflow-x: hidden;
  transition: 0.5s;
}

.overlay-content1 {
  position: relative;
  width: 100%;
  text-align: center;
}

.modal-content {
  border-radius: 20px;
}

.btn .MuiSvgIcon-root {
  font-size: 1.2em !important;
}

.radius-50 {
  border-radius: 50%;
}

body.swal2-shown.swal2-height-auto {
  height: inherit !important;
}